/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';
import {smoothScroll, mediaQuery} from '../modules/common';
import {globalNaviLinkEnter, globalNaviLinkLeave, humberger} from '../modules/gnav';

/* =============================================================
 * function
 * ========================================================== */

// Barbaにて現在と同一ページのリンクをクリックした際リロードをしない設定
export function samePageNotReload() {
  const eventDelete = e => {
    if (e.currentTarget.href === window.location.href) {
      smoothScroll(e.currentTarget.hash.replace(/#/, ''));
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  const links = [...document.querySelectorAll('a[href]')];
  links.forEach(link => {
    link.addEventListener('click', e => {
      eventDelete(e);
    }, false);
  });
};


// ページ個別のメタ情報を書き換える処理
export const replaceHeadTags = target => {
  const head = document.head;
  const targetHead = target.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
  const newPageHead = document.createElement('head');
  newPageHead.innerHTML = targetHead;
  const removeHeadTags = [
    'meta[name=\'keywords\']',
    'meta[name=\'description\']',
    'meta[property^=\'fb\']',
    'meta[property^=\'og\']',
    'meta[name^=\'twitter\']',
    'meta[name=\'robots\']',
    'meta[itemprop]',
    'link[itemprop]',
    'link[rel=\'prev\']',
    'link[rel=\'next\']',
    'link[rel=\'canonical\']',
  ].join(',');
  const headTags = [...head.querySelectorAll(removeHeadTags)];
  headTags.forEach(item => {
    head.removeChild(item);
  });
  const newHeadTags = [...newPageHead.querySelectorAll(removeHeadTags)];
  newHeadTags.forEach(item => {
    head.appendChild(item);
  });
};


// GoogleAnalyticsにアクセス情報を送る処理
export const gaSend = pathname => {
  gtag('set', 'page_path', pathname);  // eslint-disable-line no-undef
  gtag('event', 'page_view');  // eslint-disable-line no-undef
};

export const snsBtnReload = (url) => {
  const sns = document.getElementById('article_sns');
  if (sns) {
    FB.XFBML.parse(); // eslint-disable-line no-undef
    twttr.widgets.load(); // eslint-disable-line no-undef
    Hatena.Bookmark.BookmarkButton.setup(); // eslint-disable-line no-undef
    LineIt.loadButton(); // eslint-disable-line no-undef
    noteLoader(url);
  }
};

// noteボタンの公式JSデータを参照
// 参照：https://cdn.st-note.com/js/social_button.min.js
function noteLoader(url) {
  let urlParam;
  let hashtagParam;
  const t = "https://note.com";
  const e = document.querySelectorAll(".note-social-button");
  Array.prototype.map.call(e, function(e) {
    const r = e.getAttribute("data-url");
    const a = e.getAttribute("data-hashtags");
    urlParam = r ? r : url,
      urlParam.match(/^https?:\/\//) && urlParam || (urlParam = t),
      urlParam = "?url=" + encodeURIComponent(urlParam),
      hashtagParam = a ? "&hashtags=" + encodeURIComponent(a) : "";  // eslint-disable-line no-undef
    const o = document.createElement("iframe");
    const n = t + "/intent/social_button" + urlParam + hashtagParam;  // eslint-disable-line no-undef
    o.setAttribute("src", n), o.setAttribute("width", 80), o.setAttribute("height", 20), o.setAttribute("style", "border: none; overflow: hidden;"), o.setAttribute("scrolling", "no"), o.setAttribute("frameborder", 0), o.setAttribute("allowTransparency", !0), o.setAttribute("title", "note\u3067\u66f8\u304f\u30dc\u30bf\u30f3"), e.parentNode.insertBefore(o, e), e.remove();  // eslint-disable-line no-undef
  });
}

// barbaページ遷移後のスクロール位置調整
export function scrollAdjuster() {
  // ヘッダー追従かどうか
  const headerFixed = true;

  // 調整値
  const adjuster = 60;

  // URLに「#」が存在するか
  if (location.hash) {
    const anchor = document.querySelector(location.hash);
    if (anchor) {
      const rect = anchor.getBoundingClientRect();
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (headerFixed) {
        const header = document.getElementById('header');
        if (header) {
          scrollTop = scrollTop - header.clientHeight;
        }
      }
      const top = rect.top + scrollTop - adjuster;
      console.log(top);

      window.scrollTo(0, top);
    } else {
      // アンカー先が存在しなければページトップに
      window.scrollTo(0, 0);
    }
  } else {
    // URLに「#」が存在しなければページトップに
    window.scrollTo(0, 0);
  }
}


export const customPreventCheck = (elm, event) => {

  if (elm.hasAttribute('target')) {
    if (elm.getAttribute('target') == '_blank') {
      console.log('targetBlank');
      return true;
    }
  }

  // 該当クラスに属していればBarbaを無効に
  const ignoreClasses = ['no-barba'];
  for (let i = 0; i < ignoreClasses.length; i++) {
    if (elm.classList.contains(ignoreClasses[i])) {
      console.log('no-barba');
      return true;
    }
  }

  // アンカーリンクであり同一ページでなければPjaxを有効に
  const url = location.protocol + '//' + location.host + location.pathname;
  const extractHash = elm.href.replace(/#.*$/, '');
  const hashIndex = elm.href.indexOf('#');

  if (elm.href.startsWith(location.protocol + '//' + location.host)) {

    // ハッシュを持つ場合
    if (hashIndex > -1) {
      if (extractHash != url) {
        // 他ページのアンカーリンクの場合
        return false; // pjax有効
      } else {
        // 同一ページ上でのアンカーリンクの場合
        if (event.type == 'click') {
          const urlHash = elm.hash.replace(/#/, '');
          smoothScroll(urlHash); // スムーススクロールを実行
          return true; // pjax無効
        }
      }
      return false; // pjax有効
    }
  }

  // 拡張子が該当する場合はtarget='_blank'に
  if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(elm.href.toLowerCase())) {
    elm.setAttribute('target', '_blank');
    return true;
  }
};


/* ********************************************
 * resetAnyFunc
 * ページ読み込み時の処理リセット用関数
 * ****************************************** */
export function resetAnyFunc() {
  $(window).off('scroll.arwAnim');
  const parentElements = document.querySelectorAll('.header_gnav_parent_list');
  const hum = document.getElementById('hum');
  // const logo = document.getElementById('header_nav_logo_ico');
  const listElements = document.querySelectorAll('.header_gnav_list_item_link');
  const listElementsChild = document.querySelectorAll('.header_gnav_child_list_item_link');
  const listLoginBtn = document.querySelectorAll('.header_login_btn');
  const headerOfferBtn = document.querySelectorAll('.header_offer_btn');
  const windowWidth = window.innerWidth;

  parentElements.forEach(elem => {
    elem.removeEventListener('mouseenter', {
      elem: elem,
      handleEvent: globalNaviLinkEnter,
    }, false);
    elem.removeEventListener('mouseleave', {
      elem: elem,
      handleEvent: globalNaviLinkLeave,
    }, false);
  });

  // SP HumbergerMneu
  hum.removeEventListener('click', humberger);
  // logo.removeEventListener('click', humberger);

  if (mediaQuery(windowWidth) == 'sp') {
    listElements.forEach(elem => {
      elem.removeEventListener('click', elem.humberger, false);
    });
    listElementsChild.forEach((elem) => {
      elem.removeEventListener('click', elem.humberger, false);
    });
    listLoginBtn.forEach((elem) => {
      elem.removeEventListener('click', humberger, false);
    });
    headerOfferBtn.forEach((elem) => {
      elem.removeEventListener('click', humberger, false);
    });
    $("#nav_blog_parent > .header_gnav_list_item_link").off('click.SpSubMenuBtn');
  }

}
