/* =============================================================
 * import
 * ========================================================== */
import {mediaQuery} from './common';
import $ from "jquery";

/* =============================================================
 * globalNavi
 * グローバルナビに関する実行関数
 * ========================================================== */
export function globalNavi(path) {
  const windowWidth = window.innerWidth;
  const listElements = document.querySelectorAll('.header_gnav_list_item_link');
  const listElementsChild = document.querySelectorAll('.header_gnav_child_list_item_link');
  const listLoginBtn = document.querySelectorAll('.header_login_btn');
  const headerOfferBtn = document.querySelectorAll('.header_offer_btn');
  const parentElements = document.querySelectorAll('.gnav_list_parent');
  const childElements = document.querySelectorAll('.header_gnav_children');
  const filterElement = document.getElementById('header_gnav_filter');
  const currentPageClass = 'header_gnav_list_open';
  const hum = document.getElementById('hum');
  const header = document.getElementById('header');
  const mainContents = document.getElementById('main');

  if (mediaQuery(windowWidth) !== 'sp') {
    /* ----- PC ----- */

    if (listElements) {
      // Display
      // グローバルナビ一つずつに対して処理
      listElements.forEach((elem) => {
        if (elem.pathname == path) {
          // 現在表示中のページと一致するメニューの場合
          // 何もしない

        } else {
          // 遷移前に表示中だったメニューからクラスを除去
          elem.parentElement.classList.remove(currentPageClass);
          // サブメニューを持たず、表示中のページとも一致しないメニューの場合
          hiddenSubNavi(elem, filterElement, false);
        }
      });
    }

    // MouseEnter/Leave
    if (parentElements) {
      parentElements.forEach((elem) => {
        elem.addEventListener('mouseenter', () => {
          globalNaviLinkEnter(elem);
        }, false);
        elem.addEventListener('mouseleave', () => {
          globalNaviLinkLeave(elem);
        }, false);
        elem.addEventListener('click', () => {
          globalNaviLinkLeave(elem);
        }, false);
      });
    }

    if (childElements) {
      childElements.forEach((elem) => {
        elem.style.visibility = 'hidden';
      });
    }

    if (filterElement) {
      filterElement.classList.remove('open');
      setTimeout(() => {
        filterElement.style.visibility = 'hidden';
        filterElement.style.transform = 'translateY(100%)';
      }, 100);
    }

    // headerのサイズ調整
    mainContents.style.paddingTop = header.offsetHeight + 'px';
    window.addEventListener('resize', function() {
      mainContents.style.paddingTop = header.offsetHeight + 'px';
    });

    } else {
    /* ----- SP ----- */
    if (hum) {
      // SP HumbergerMneu
      hum.addEventListener('click', humberger);
      listElements.forEach((elem) => {
        if (elem.parentNode.classList.contains('gnav_list_parent') == false) {
          elem.addEventListener('click', humberger, false);
        }
      });
      listElementsChild.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
      listLoginBtn.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
      headerOfferBtn.forEach((elem) => {
        elem.addEventListener('click', humberger, false);
      });
    }

    // サブカテゴリー開閉
    $("#nav_blog_parent > .header_gnav_list_item_link").on('click.SpSubMenuBtn', function () {
      $(this).toggleClass('active');
      $(this).next(".header_gnav_children").slideToggle(500).toggleClass('open');
      return false;
    });

  }

}


/* =============================================================
 * humberger
 * ハンバーガーメニューの制御
 * ========================================================== */

export function humberger() {

  const html = document.documentElement;
  const hum = document.getElementById('hum');
  const gnav = document.getElementById('gnav');
  const filter = document.getElementById('filter');
  const logo = document.getElementById('header_logo');

  if (hum) {

    if (hum.classList.contains('close')) {

      hum.classList.remove('close');
      gnav.classList.remove('close');
      filter.classList.remove('close');
      html.classList.remove('is-fixed');
      logo.addEventListener('click', humberger);

    } else if (hum.classList.contains('open')) {

      hum.classList.add('close');
      gnav.classList.add('close');
      filter.classList.add('close');
      html.classList.add('is-fixed');
      logo.removeEventListener('click', humberger);

    }
    hum.classList.toggle('open');
    gnav.classList.toggle('open');
    filter.classList.toggle('open');
    html.classList.toggle('is-fixed');

  }

}


/* =============================================================
 * globalNaviLinkEnter
 * グローバルナビへmouseenterした際の挙動
 * ========================================================== */
export function globalNaviLinkEnter(e) {
  let timerIdSub;
  let timerIdBg;
  let timerIdCurrent;
  const currentElem = document.querySelector('.header_gnav_list_open');
  const filterElement = document.getElementById('header_gnav_filter');
  const navPositionTop = document.getElementById('header').clientHeight;

  if (e.classList.contains('header_gnav_list_open')) {

    // .header_gnav_list_open を持っている場合は何もしない。

  } else if (e.lastElementChild.classList.contains('header_gnav_children')) {

    // サブメニューの表示
    timerIdSub = e.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      // setTimeoutで遅延を入れているので、
      // 遅延中にenter処理が入った際に上書きができず、
      // 以下のsetTimeout処理が走らないため、clearTimeoutで解消。
      clearTimeout(timerIdSub);
    }
    e.lastElementChild.style.visibility = 'visible';
    timerIdSub = setTimeout(() => {
      e.firstElementChild.classList.add('open');
      e.lastElementChild.classList.add('open');
    }, 100);
    e.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

    // 現在表示中（該当ページ）のサブメニュー
    if (currentElem) {
      if (currentElem.lastElementChild.classList.contains('header_gnav_children')) {
        timerIdCurrent = currentElem.lastElementChild.dataset.timerId;
        if (timerIdCurrent) {
          clearTimeout(timerIdCurrent);
        }

        currentElem.firstElementChild.classList.remove('open');
        currentElem.lastElementChild.classList.remove('open');
        timerIdCurrent = setTimeout(() => {
          currentElem.lastElementChild.style.visibility = 'hidden';
        }, 200);
        currentElem.lastElementChild.dataset.timerId = timerIdCurrent; // タイマーIDが引き渡せないので、data属性に格納
      }
      currentElem.classList.add('tmp_close');
    }

    // サブメニュー背景
    timerIdBg = filterElement.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdBg) {
      // setTimeoutで遅延を入れているので、
      // 遅延中にenter処理が入った際に上書きができず、
      // 以下のsetTimeout処理が走らないため、clearTimeoutで解消。
      clearTimeout(timerIdBg);
    }
    filterElement.style.visibility = 'visible';
    filterElement.style.transform = 'translateY(' + navPositionTop + 'px)';
    timerIdBg = setTimeout(() => {
      filterElement.classList.add('open');
    }, 100);
    filterElement.dataset.timerId = timerIdBg; // タイマーIDが引き渡せないので、data属性に格納
  } else {
    if (currentElem) {
      currentElem.classList.add('tmp_close');
    }
  }
}


/* =============================================================
 * globalNaviLinkLeave
 * グローバルナビからmouseleaveした際の挙動
 * ========================================================== */
export function globalNaviLinkLeave(e) {
  let timerIdSub;
  let timerIdBg;
  // let timerIdCurrent;
  const filterElement = document.getElementById('header_gnav_filter');
  // const path = location.pathname;
  const currentElem = document.querySelector('.header_gnav_parent_list_open');

  if (e.classList.contains('header_gnav_parent_list_open')) {

    // .header_gnav_parent_list_open を持っている場合は何もしない。

  } else if (e.lastElementChild.classList.contains('header_gnav_children')) {
    // サブメニューの非表示
    timerIdSub = e.lastElementChild.dataset.timerId; // clearTimeout用のタイマーID
    if (timerIdSub) {
      clearTimeout(timerIdSub);
    }

    e.firstElementChild.classList.remove('open');
    e.lastElementChild.classList.remove('open');
    timerIdSub = setTimeout(() => {
      e.lastElementChild.style.visibility = 'hidden';

    }, 200);
    // mouseenterの方でもsetTimeoutで遅延を入れているので、
    // timerIdを引き渡して遅延中にenter処理が入った際にキャンセルを挿入。
    e.lastElementChild.dataset.timerId = timerIdSub; // タイマーIDが引き渡せないので、data属性に格納

    // サブメニュー背景
    timerIdBg = filterElement.dataset.timerId;
    if (timerIdBg) {
      clearTimeout(timerIdBg);
    }
    filterElement.classList.remove('open');
    timerIdBg = setTimeout(() => {
      // filterElement.style.visibility = 'hidden';
      filterElement.style.transform = 'translateY(100%)';
    }, 200);
    // mouseenterの方でもsetTimeoutで遅延を入れているので、
    // timerIdを引き渡して遅延中にenter処理が入った際にキャンセルを挿入。
    filterElement.dataset.timerId = timerIdBg; // タイマーIDが引き渡せないので、data属性に格納
  } else {
    if (currentElem) {
      currentElem.classList.remove('tmp_close');
    }
  }
}


/* =============================================================
 * hiddenSubNavi
 * サブメニュー非表示用
 * ========================================================== */
function hiddenSubNavi(elem, filterElement, flg) {

  // サブメニューの非表示
  if (elem.nextElementSibling) {
    if (elem.nextElementSibling.classList.contains('header_gnav_children')) {
      elem.classList.remove('open');
      elem.nextElementSibling.classList.remove('open');
      setTimeout(() => {
        elem.nextElementSibling.style.visibility = 'hidden';
      }, 200);
    }
  }

  // サブメニュー背景非表示
  if (flg === true) {
    filterElement.classList.remove('open');
    setTimeout(() => {
      // filterElement.style.visibility = 'hidden';
      filterElement.style.transform = 'translateY(100%)';
    }, 200);
  }

}


/* =============================================================
 * checkParentDirectory
 * 現在のディレクトリ名からサブページの有無を判定
 * ========================================================== */
export function checkParentDirectory(path) {
  let reg;
  let result;
  let hasChildDir = false;

  // 表示中のページ判定
  const childPathArray = ['/service/', '/works/', '/company/', '/about/', '/media/', '/contact/'];
  childPathArray.forEach(dir => {
    reg = new RegExp(dir);
    result = path.match(reg);
    if (result != null) {
      hasChildDir = true;
      return;
    }
  });
  return hasChildDir;
}

