/* =============================================================
 * import
 * ========================================================== */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import { gsap } from 'gsap';
import * as barbaOpt from './options';
import * as scrollObserver from '../modules/scrollObserver';
import * as routeCommon from '../route/common';
import top from '../route/top';
import news from '../route/news';
import contact from '../route/contact';
import {globalNavi} from '../modules/gnav';
import {modalWindow} from '../modules/common';

/* =============================================================
 * variables
 * ========================================================== */

// アニメーション用の変数定義
const $body = document.body;
const domain = document.domain;
const tt = document.querySelectorAll('.tt_layer');
const $header = document.getElementById('header');

export default function barbaRun() {

  barba.use(barbaPrefetch);
  barba.init({
    debug: true,
    logLevel: 'debug',
    schema: {
      prefix: 'data-trans',
      wrapper: 'wrapper',
      container: 'container',
      namespace: 'namespace',
    },
    prevent: ({el, event}) => barbaOpt.customPreventCheck(el, event),
    // キャッシュを無効にするページ指定
    cacheIgnore: [
      '/contact/',
      '/contact/confirmation/',
      '/contact/thanks/',
    ],
    views: [
      {
        namespace: 'home',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          // routeTop.sliders();
          top();
        },
      },
      {
        namespace: 'news',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          news();
        },
      },
      {
        namespace: 'contact',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          contact();
        },
      },
    ],

    transitions: [
      {
        // 全ページ共通のトランジション
        name: 'global-transitions',
        // sync: true, //同時実行モード
        async before() {
          console.log('before');
        },
        async beforeLeave() {
          console.log('beforeLeave');
        },
        async leave(data) {
          console.log('leave');
          barbaOpt.resetAnyFunc(data); // 各イベントトリガーのリセット
          ttStart();

          gsap.to(tt, {
            startAt: {
              width: 0,
              height: '100%',
              opacity: 0,
              xPercent: 0,
            },
            duration: .5,
            opacity: 1,
            width: '100%',
            backgroundColor: '#e7211a',
          });
          await new Promise((resolve) => {
            return setTimeout(resolve, 500);
          });
        },
        async afterLeave() {
          console.log('afterLeave');
        },
        async beforeEnter(data) {
          console.log('beforeEnter');
          globalNavi(data.next.url.path);
          modalWindow();
          gsap.to(tt, {
            duration: .5,
            backgroundColor: '#1e2c5b',
          });
          await new Promise((resolve) => {
            return setTimeout(resolve, 500);
          });
        },
        async enter() {
          console.log('enter');
        },
        async afterEnter() {
          console.log('afterEnter');
          scrollObserver.floating();
        },
        async after() {
          console.log('after');
          ttEnd();
          setTimeout(() => {
            gsap.to(tt, {
              duration: .5,
              xPercent: 100,
            });
          }, 0);
        },
      },
      {
        // 各ルールに応じた個別トランジションの設定
        name: 'custom-transitions',
        to: { // どのページへの遷移か
          namespace: [
            'company',
          ],
        },
        from: { // どのページからの遷移か
          namespace: [
            'home',
          ],
        },

        async leave(data) {
          console.log(data);
        },

        async after(data) {
          console.log(data);
        },
      },
    ],

  });

  barba.hooks.beforeEnter(({next}) => {
    barbaOpt.replaceHeadTags(next);
    barbaOpt.scrollAdjuster();
    routeCommon.cursor();
    routeCommon.cursorAnimation();
    if (domain === 'example.com') {
      barbaOpt.gaSend(next.url.path);
    }
    barbaOpt.snsBtnReload(next.url.href);
    routeCommon.before(next);
  });
  barba.hooks.afterEnter(({next}) => {
    // document.getElementById('cursor').classList.remove('active');
    routeCommon.after(next);
  });
  barbaOpt.samePageNotReload();

  /*
   * Transitions
   *  beforeOnce, once, afterOnce, before,
   *  beforeLeave, leave, afterLeave, afterLeave,
   *  enter, afterEnter, after
   * Views
   *  beforeLeave, afterLeave, beforeEnter, afterEnter
   * */

}

// トランジション開始時の設定
function ttStart() {
  $body.classList.remove('loaded');
  $body.classList.add('loading');
  $header.classList.remove('scrolled');
  $header.style.pointerEvents = 'none';
}

// トランジション終了時の設定
function ttEnd() {
  $body.classList.remove('loading');
  $body.classList.add('loaded');
  $header.style.pointerEvents = 'auto';
}

