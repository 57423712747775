/* =============================================================
 * import
 * ========================================================== */
import ScrollHint from 'scroll-hint';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  tableScrollHint();

  window.addEventListener('resize', function() {
    tableScrollHint();
  });
}

function tableScrollHint() {
  console.log('testsetsest');
  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function() {
      new ScrollHint(scrollableAry, {
        scrollHintIconAppendClass: 'scroll-hint-icon-white',
        // applyToParents: true,
        i18n: {
          scrollable: 'スクロールできます',
        },
      });
    });
  }
}
