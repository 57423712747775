/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  // googleReCaptchaV3();
  formAddErrorClass();
  /*
  window.addEventListener('resize', function() {
  });
  */
}

function formAddErrorClass () {
  if ($(".mw_wp_form").length) {
    $(".form_list_cnt").each(function () {
      if ($(this).find('.error').length) {
        $(this).addClass('error_field');
      }
    });
  }
}

/*
function googleReCaptchaV3() {
  const mwWpForm = document.querySelector('.mw_wp_form > form');
  const mwWpFormSubmit = document.querySelector('input[type="submit"][name="submitConfirm"]');
  mwWpForm.addEventListener('submit', onReCAPTCHA);

  // リキャプチャの制御
  function onReCAPTCHA(e) {
    e.preventDefault();
    grecaptcha.ready(function() { // eslint-disable-line no-undef
      grecaptcha.execute('6LeAAKMgAAAAAFCiPUgfseoXlbiMFJOIFhSaZuyb', { // eslint-disable-line no-undef
        action: 'submit',
      }).then(function(token) {
        const recaptchaResponse = document.querySelector('input[name="recaptcha-v3"]');
        recaptchaResponse.value = token;
        mwWpForm.submit();
      });
    });
  }

  const mwWpFormPrivacy = document.querySelector('#privacy-1');
  disabledSubmit(mwWpFormPrivacy);
  mwWpFormPrivacy.addEventListener('change', disabledSubmit);

  // プライバシーポリシーのチェックに応じてボタンをdisabled
  function disabledSubmit(event) {
    let flg;
    if (event.target === undefined) {
      flg = event.checked;
    } else {
      flg = event.target.checked;
    }

    if (flg == true) {
      mwWpFormSubmit.disabled = false;
    } else {
      mwWpFormSubmit.disabled = true;
    }
  }
}
*/

