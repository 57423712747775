/* =============================================================
 * import
 * ========================================================== */
import 'intersection-observer';
// import Stickyfill from 'stickyfilljs';


/* =============================================================
 * function
 * ========================================================== */
export function floating() {
  floatingHeader();
  // floatingSidebar();
  // floatingArrow();
}

function floatingHeader() {

  // intersection-observerAPIだと、
  // ページ遷移時に要素の重なりを検知してしまい、
  // 誤作動が起きるため、以下の書き方。
  let documentElem;
  const userAgent = window.navigator.userAgent;
  const header = document.querySelector('#header');
  const hum = document.querySelector('#hum');

  window.onscroll = function() {

    if (userAgent.toLowerCase().match(/Safari|MSIE/)) {
      // Webkit系（Safari, iOS）、IE5はbody要素
      documentElem = document.body;
    } else {
      // IE（6以上）、Chrome, Firefox、Operaはhtml要素
      documentElem = document.documentElement;
    }

    const positionTop = documentElem.scrollTop;
    if (positionTop > 130) {
      header.classList.add('scrolled');
      hum.classList.add('scrolled');
    } else {
      header.classList.remove('scrolled');
      hum.classList.remove('scrolled');
    }

  };

}


/*
function floatingSidebar() {

  const elm = document.querySelectorAll('.sticky');
  Stickyfill.add(elm);
}
*/


/*
function floatingArrow() {

  const floatingObj = document.querySelector('#totop');
  const targetObj = document.querySelector('#footer');
  const windowHeight = window.innerHeight;
  const floatingObjHeight = floatingObj.clientHeight;
  const floatingObjRect = floatingObj.getBoundingClientRect();
  const floatingObjRectBottom = floatingObjRect.bottom;
  const baseMargin = (windowHeight - floatingObjRectBottom + (floatingObjHeight / 2));
  const options = {
    root: null,
    rootMargin: -baseMargin + 'px 0px',
    threshold: 0,
  };

  const doWhenIntersect = (entries, observer) => { // eslint-disable-line @typescript-eslint/no-unused-vars
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        floatingObj.classList.add('fixed');
        // entry.target.classList.add('intersection');
      } else {
        floatingObj.classList.remove('fixed');
        // entry.target.classList.remove('intersection');
      }
    });
  };
  const observer = new IntersectionObserver(doWhenIntersect, options);

  if (targetObj) {
    observer.observe(targetObj);
  }

}
*/
